import { toJS } from "mobx"
import { getRoot } from "mobx-state-tree"
export const generateImageStoreViews = self => ({
    get promptsObjectConverted() {
        if (self.promptsObject == null || self.promptsObject == undefined) {
            return []
        } else {
            return JSON.parse(self.promptsObject)
        }
      },
      get presetsList() {
        if (self.presets == null || self.presets == undefined) {
            return []
        } else {
            return self.presets
        }
      },
      get selectedPresetId() {
        if (self.selectedPreset == null || self.selectedPreset == undefined) {
            return null
        } else {
            return self.selectedPreset.id
        }
      },
      get jobsList() {
        if (self.jobs == null || self.jobs == undefined) {
            return []
        } else {
            return self.jobs
        }
      },
      get fakeJobsList() {
        if (self.fakeJobs == null || self.fakeJobs == undefined) {
            return []
        } else {
            return self.fakeJobs
        }
      },
      get isImageUploadingView() {
        if (self.isImageUploading == null || self.isImageUploading == undefined) {
            return false
        } else {
            return self.isImageUploading
        }
      },
      get userPromptView() {
        if (self.userPrompt == null || self.userPrompt == undefined) {
            return ''
        } else {
            return self.userPrompt
        }
      },
      get userPromptNegativeView() {
        if (self.userPromptNegative == null || self.userPrompt == undefined) {
            return ''
        } else {
            return self.userPromptNegative
        }
      },
      get userPromptSeedView() {
        if (self.userPromptSeed == null || self.userPromptSeed == undefined || self.userPromptSeed == '') {
            return 1000
        } else {
            return Number(self.userPromptSeed)
        }
      },
      get userPromptStepsView() {
        if (self.userPromptSteps == null || self.userPromptSteps == undefined || self.userPromptSteps == '') {
            return 25
        } else {
            return Number(self.userPromptSteps)
        }
      },
      get customUserPrompt() {
        if (self.userPrompt == null || self.userPrompt == undefined) {
            return ''
        } else {
            return self.userPrompt
        }
      },
    get isCustomSceneSelected() {
        if (self.customScene) {
            if (self.customScene === true) {
                return true
            } else if (self.customScene === false)  {
                return false
            }
        } else {
            return false
        }
    },
    get isImageSelectedAndHasUrl() {
        if (self.imageUrl) {
            return true
        } else {
            return false
        }
      },
    get resultButtonLabelView() {
        if (self.resultButtonLabel) {
            return self.resultButtonLabel
        } else {
            return ''
        }
    },
    get uploadProgressView() {
        if (self.uploadProgress == null || self.uploadProgress == undefined) {
            return 0
        } else {
            return self.uploadProgress
        }
    },
    get uploadStateView() {
        if (self.isImageUploading == null) {
            return "normal"
        } else {
            if (self.isImageUploading == true && self.uploadProgressView <= 99 ) {
            return "loading"
            } else if (self.isImageUploading == true && self.uploadProgressView == 100) {
                return "processing"
            } else {
                return "normal"
            }
        }
    },
    get assetsFactory() {
    return getRoot(self).assetsFactory
    },
    get presetsFactory() {
        return getRoot(self).presetsFactory
        },
    get accountStore() {
        return getRoot(self).accountStore
    },
    get jobsFactory() {
        return getRoot(self).jobsFactory
    },
    get productsFactory() {
        return getRoot(self).productsFactory
    },
    get guiStore() {
        return getRoot(self).guiStore
    },
    get settingsStore() {
        return getRoot(self).settingsStore
    },
    get queryStore() {
        return getRoot(self).queryStore
    },
    get isSelectedImageError() {
        if (self.selectedImageError) {
            return true
        } else {
            return false
        }
      },
    get isSelectedPresetError() {
        if (self.selectedPresetError) {
            return true
        } else {
            return false
        }
    },
    get isProductTypeError() {
        if (self.productTypeError) {
            return true
        } else {
            return false
        }
    },
    get valid() {
        // self.validImage()
        // self.validPreset()
        // self.validProductType()
        // return ( 
        //     self.selectedImageError || 
        //     self.selectedPresetError ||
        //     self.productTypeError
        //     ) === null

        return self.isRequestValid
    },
    get isRequestValid() {
        if (
            self.isCustomOrPresetSelected &&
            self.isProductSelected &&
            self.ifComfyWorkflowValid
        ) {
            return true
        } else {
            return false
        }
    },

    get ifComfyWorkflowValid() {
        if (self.settingsStore.isComfyWorkflow) {
            return self.selectedReferenceImage?.id
        } else {
            return true
        }
    },
    get isAssetLocalOrUploadingOrOnline() {
        if (self.selectedImage !== null || self.isImageUploading === true) {
            return true
        } else {
            return false
        }
    },

    get isCustomOrPresetSelected() {
        if (self.selectedPreset !== null || self.isCustomSceneSelected === true) {
            return true
        } else {
            return false
        }
    },

    get isProductTypeSelected() {
        if (self.productType !== null && self.productType !== undefined && self.productType !== "") {
            return true
        } else {
            return false
        }
    },

    get isProductSelected() {
        return self.selectedProduct !== null
    },

    get howMuchJobsLeft() {
        const allowedJobs = 20
        const jobsPerRender= 2

        if (self.jobs !== null && self.jobs !== undefined) {
            if (toJS(self.jobs.length) > allowedJobs ) return 0
            const leftJobs = allowedJobs - toJS(self.jobs.length)
            return leftJobs / jobsPerRender
        } else {
            return 0
        }
    },
    get areThereFreeJobsLeft() {
        if (self.howMuchJobsLeft === 0) {
            return false
        } else {
            return true
        }
    },
    get renderButtonCounterView() {
        if (self.areThereFreeJobsLeft === true) {
            return `${self.howMuchJobsLeft} free shots left`
        } else {
            return 'Upgrade plan'
        }
    },
    get doesTheUserHaveRightToRender() {
        return true
        // if (self.areThereFreeJobsLeft) {
        //     return true
        // } else if (self.accountStore.hasActiveStripeSubsciption ) {
        //     return true
        // } else if (self.areThereFreeJobsLeft == false && self.accountStore.hasActiveStripeSubsciption == false) {
        //     return false
        // }
    },

    get adminGenerateSettings() {
        return {
            num_inference_steps: self.numInferenceSteps,
            guidance_scale: self.guidanceScale,
            model: self.fluxModel,
            extra_lora: self.extraLora,
            extra_lora_scale: self.extraLoraScale
        }
    }
    
})